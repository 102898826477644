import {
  FETCH_JOBS,
  FETCH_JOBS_SUCCESS,
  FETCH_JOBS_FAILURE,
  FETCH_JOBS_ID,
  FETCH_JOBS_ID_SUCCESS,
  FETCH_JOBS_ID_FAILURE,
  FETCH_JOB_CONSIGNMENT,
  FETCH_JOB_CONSIGNMENT_SUCCESS,
  FETCH_JOB_CONSIGNMENT_FAILURE,
  FETCH_JOB_CONSIGNMENT_ITEM,
  FETCH_JOB_CONSIGNMENT_ITEM_SUCCESS,
  FETCH_JOB_CONSIGNMENT_ITEM_FAILURE,
  FETCH_JOB_AWB,
  FETCH_JOB_AWB_SUCCESS,
  FETCH_JOB_AWB_FAILURE,
  FETCH_JOB_AWB_LOOSE,
  FETCH_JOB_AWB_LOOSE_SUCCESS,
  FETCH_JOB_AWB_LOOSE_FAILURE,
  FETCH_JOB_AWB_ULD,
  FETCH_JOB_AWB_ULD_SUCCESS,
  FETCH_JOB_AWB_ULD_FAILURE,
  GET_JOB_RUNSHEET,
  GET_JOB_RUNSHEET_SUCCESS,
  GET_JOB_RUNSHEET_FAILURE,
  DOWNLOAD_PDF_JOB_RUNSHEET,
  DOWNLOAD_PDF_JOB_RUNSHEET_SUCCESS,
  DOWNLOAD_PDF_JOB_RUNSHEET_FAILURE,
  DOWNLOAD_CSV_JOB_RUNSHEET,
  DOWNLOAD_CSV_JOB_RUNSHEET_SUCCESS,
  DOWNLOAD_CSV_JOB_RUNSHEET_FAILURE,
} from "@utils/actionTypes";

const INIT_STATE = {
  loadingJobs: false,
  jobsData: null,
  loadingJobsById: false,
  jobByIdData: null,
  loadingJobConsignment: false,
  jobConsignmentData: null,
  loadingAirWayBill: false,
  airWayBillData: null,
  loadingConsignmentItems: false,
  consignmentItems: null,
  loadingAwbLoose: false,
  awbLooseData: null,
  loadingAwbUld: false,
  awbUldData: null,
  gettingJobRunsheet: false,
  jobRunSheetData: null,
  downloadingPdfJobRunsheet: false,
  downloadingCsvJobRunsheet: false,
};

const JobsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_JOBS:
      return { ...state, loadingJobs: true };
    case FETCH_JOBS_SUCCESS:
      return {
        ...state,
        jobsData: action.payload,
        loadingJobs: false,
      };
    case FETCH_JOBS_FAILURE:
      return {
        ...state,
        jobsData: action.payload,
        loadingJobs: false,
      };

    case FETCH_JOBS_ID:
      return { ...state, loadingJobsById: true };
    case FETCH_JOBS_ID_SUCCESS:
      return {
        ...state,
        jobByIdData: action.payload,
        loadingJobsById: false,
      };
    case FETCH_JOBS_ID_FAILURE:
      return {
        ...state,
        jobByIdData: action.payload,
        loadingJobsById: false,
      };

    case FETCH_JOB_CONSIGNMENT:
      return { ...state, loadingJobConsignment: true };
    case FETCH_JOB_CONSIGNMENT_SUCCESS:
      return {
        ...state,
        jobConsignmentData: action.payload,
        loadingJobConsignment: false,
      };
    case FETCH_JOB_CONSIGNMENT_FAILURE:
      return {
        ...state,
        jobConsignmentData: action.payload,
        loadingJobConsignment: false,
      };

    case FETCH_JOB_CONSIGNMENT_ITEM:
      return { ...state, loadingConsignmentItems: true };
    case FETCH_JOB_CONSIGNMENT_ITEM_SUCCESS:
      return {
        ...state,
        consignmentItems: action.payload,
        loadingConsignmentItems: false,
      };
    case FETCH_JOB_CONSIGNMENT_ITEM_FAILURE:
      return {
        ...state,
        consignmentItems: action.payload,
        loadingConsignmentItems: false,
      };

    case FETCH_JOB_AWB:
      return { ...state, loadingAirWayBill: true };
    case FETCH_JOB_AWB_SUCCESS:
      return {
        ...state,
        airWayBillData: action.payload,
        loadingAirWayBill: false,
      };
    case FETCH_JOB_AWB_FAILURE:
      return {
        ...state,
        airWayBillData: action.payload,
        loadingAirWayBill: false,
      };

    case FETCH_JOB_AWB_LOOSE:
      return { ...state, loadingAwbLoose: true };
    case FETCH_JOB_AWB_LOOSE_SUCCESS:
      return {
        ...state,
        awbLooseData: action.payload,
        loadingAwbLoose: false,
      };
    case FETCH_JOB_AWB_LOOSE_FAILURE:
      return {
        ...state,
        awbLooseData: action.payload,
        loadingAwbLoose: false,
      };

    case FETCH_JOB_AWB_ULD:
      return { ...state, loadingAwbUld: true };
    case FETCH_JOB_AWB_ULD_SUCCESS:
      return {
        ...state,
        awbUldData: action.payload,
        loadingAwbUld: false,
      };
    case FETCH_JOB_AWB_ULD_FAILURE:
      return {
        ...state,
        awbUldData: action.payload,
        loadingAwbUld: false,
      };

    case GET_JOB_RUNSHEET:
      return { ...state, gettingJobRunsheet: true };
    case GET_JOB_RUNSHEET_SUCCESS:
      return {
        ...state,
        jobRunSheetData: action.payload,
        gettingJobRunsheet: false,
      };
    case GET_JOB_RUNSHEET_FAILURE:
      return {
        ...state,
        jobRunSheetData: action.payload,
        gettingJobRunsheet: false,
      };

    case DOWNLOAD_CSV_JOB_RUNSHEET:
      return { ...state, downloadingCsvJobRunsheet: true };
    case DOWNLOAD_CSV_JOB_RUNSHEET_SUCCESS:
      return {
        ...state,

        downloadingCsvJobRunsheet: false,
      };
    case DOWNLOAD_CSV_JOB_RUNSHEET_FAILURE:
      return {
        ...state,

        downloadingCsvJobRunsheet: false,
      };

    case DOWNLOAD_PDF_JOB_RUNSHEET:
      return { ...state, downloadingPdfJobRunsheet: true };
    case DOWNLOAD_PDF_JOB_RUNSHEET_SUCCESS:
      return {
        ...state,

        downloadingPdfJobRunsheet: false,
      };
    case DOWNLOAD_PDF_JOB_RUNSHEET_FAILURE:
      return {
        ...state,

        downloadingPdfJobRunsheet: false,
      };

    default:
      return state;
  }
};
export default JobsReducer;
